var generic = generic || {};

(function($) {
  $(document).on('perlgem.user.fetch', function(e, deferred) {
    generic.jsonrpc.fetch({
      method: 'user.json',
      params: [],
      onSuccess: function(jsonRpcResponse) {
        const response = jsonRpcResponse.getValue();
        deferred.resolve({
          user_id: response.ELC_USER_ID,
          signed_in: response.signed_in
        });
      },
      onFailure: function(jsonRpcResponse) {
        deferred.reject(jsonRpcResponse.getError());
      },
    });
  });
})(jQuery);
